import "./StudentDetails.scss";

import data from "../../assets/data/districtData";

import React, { useEffect, useRef, useState } from "react";
import TextField from "../../components/TextField/TextField";
import DropDown from "../../components/DropDown/DropDown";
import DatePicker from "../../components/DatePicker/DatePicker";
import Button from "../../components/Button/Button";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import Loader from "../../components/Loader/Loader";

import { useSelector } from "react-redux";
import ObjectDropDown from "../../components/ObjectDropDown/ObjectDropDown";
import StudentFee from "../../components/StudentFee/StudentFee";
import LeavesList from "../../components/LeavesList/LeavesList";
import AssignedInventory from "../../components/AssignedInventory/AssignedInventory";
import StudentResults from "../StudentResults/StudentResults";
import CustomDropDown from "../../components/CustomDropDown/CustomDropDown";
import InstallmentsStudentDetailsEdit from "../../components/InstallmentsStudentDetailsEdit/InstallmentsStudentDetailsEdit";
import Modal from "../../components/Modal/Modal";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const StudentDetails = () => {
  const navigate = useNavigate();
  const printDivRef = useRef();
  const { roll_no } = useParams();

  const location = useLocation();

  const [is_senior, setis_senior] = useState(
    location.pathname.includes("seniorStudent")
  );

  //RBAC code block
  const allPermissions = useSelector((state) => state.permissions);
  let permissions = null;
  if (is_senior) {
    permissions = allPermissions
      .find((p) => p.name === "Students")
      ?.dropdowns.find((dd) => dd.name === "Senior Students")?.permissions;
  } else {
    permissions = allPermissions
      .find((p) => p.name === "Students")
      ?.dropdowns.find((dd) => dd.name === "Junior Students")?.permissions;
  }

  const tokens = JSON.parse(localStorage.getItem("ERPTokens"));

  const [districts, setdistricts] = useState([]);
  const [talukas, settalukas] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [id, setid] = useState(null);
  const [userId, setuserId] = useState(null);
  const [rollnumber, setrollnumber] = useState(null);
  const [OMRrollnumber, setOMRrollnumber] = useState(null);
  const [firstname, setFirstname] = useState(null);
  const [middlename, setmiddlename] = useState(null);
  const [lastname, setlastname] = useState(null);
  const [dob, setdob] = useState(null);
  const [fathername, setfathername] = useState(null);
  const [mothername, setmothername] = useState(null);
  const [email, setemail] = useState(null);
  const [whatsapp, setwhatsapp] = useState(null);
  const [alternateNo, setAlternateNo] = useState(null);
  const [aadhar, setaadhar] = useState(null);
  const [gender, setgender] = useState(null);
  const [stream, setstream] = useState(null);
  const [bloodgroup, setbloodgroup] = useState(null);

  const [admclass, setadmclass] = useState(null);
  const [hostel, sethostel] = useState(false);

  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({});

  const [address, setaddress] = useState(null);
  const [district, setdistrict] = useState(null);
  const [taluka, settaluka] = useState(null);
  const [pin, setpin] = useState(null);
  const [cancelled, setcancelled] = useState(null);
  const [cancellationTime, setcancellationTime] = useState(new Date());

  const [scholarshipAmount, setscholarshipAmount] = useState(0);
  const [additionalAmount, setAdditionalAmount] = useState(0);
  const [totalFees, setTotalFees] = useState("");

  const [classes, setClasses] = useState([]); // Classes list for dropdown
  const [division, setDivision] = useState(null);
  const [divisions, setDivisions] = useState([]);
  const [selectedDivisionId, setSelectedDivisionId] = useState(null);

  const [year_start, setyear_start] = useState(null);
  const [year_end, setyear_end] = useState(null);

  const [feeStructures, setfeeStructures] = useState([]); // Hostel pricing for dropdown

  const [selectedClassId, setSelectedClassId] = useState(null);
  const [bed_id, setBed_id] = useState(null);
  const [selectedBedCount, setSelectedBedCount] = useState(null);
  const [classFee, setClassFee] = useState(0);
  const [hostelFee, setHostelFee] = useState(0);
  const [installments, setinstallments] = useState([]);
  const [originalInstallments, setoriginalInstallments] = useState([]);
  const [reducedTotal, setReducedTotal] = useState(0);
  const [selectedAdditionalSubject, setSelectedAdditionalSubject] =
    useState(null);
  const [prev_roll_no, setprev_roll_no] = useState(null);
  const [additional_subject_name, setadditional_subject_name] = useState(null);
  const [additional_subject, setadditional_subject] = useState(null);

  const [messRequired, setMessRequired] = useState(false);
  const [messFee, setmessFee] = useState(null);
  const [messMonths, setMessMonths] = useState(0);
  const [messValidityDate, setMessValidityDate] = useState("");
  const [messPriceId, setmessPriceId] = useState(null);

  const [transportPriceId, settransportPriceId] = useState(null);
  const [transportRequired, setTransportRequired] = useState(false);
  const [transportMonths, setTransportMonths] = useState(0);
  const [transportFee, settransportFee] = useState(null);
  const [transportValidityDate, setTransportValidityDate] = useState("");

  const [messOptions, setmessOptions] = useState([]);
  const [transportOptions, settransportOptions] = useState([]);
  const [selectedMessPrice, setSelectedMessPrice] = useState("");
  const [selectedTransportPrice, setSelectedTransportPrice] = useState("");

  const [messages, setmessages] = useState([]);
  const [photo_url, setphoto_url] = useState(null);
  const [file, setFile] = useState(null);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [isStMsgOpen, setIsStMsgOpen] = useState(false);

  const bloodgroups = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];
  const genders = ["Male", "Female"];
  const streams = ["Engineering", "Medical"];

  const [availableBeds, setAvailableBeds] = useState([]);
  const [bedDetails, setBedDetails] = useState([]);
  const [leaves, setleaves] = useState([]);
  const [feeEdit, setfeeEdit] = useState(false);

  const academic_years = [
    "2023-2024",
    "2024-2025",
    "2025-2026",
    "2026-2027",
    "2027-2028",
    "2028-2029",
    "2029-2030",
  ];

  useEffect(() => {
    if (isLoading) return;
    setIsLoading(true);

    window.scrollTo(0, 0);
    let cities = [];
    data.map((city) => cities.push(city.name));
    setdistricts(cities);

    if (roll_no) {
      fetchStudentDetails();
    }
  }, []);

  useEffect(() => {
    if (bed_id) {
      fetchBedDetailsById();
    }
  }, [bed_id]); // Ensure this runs only when bed_id changes

  const fetchBedDetailsById = async () => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.get(`${BE_URL}/api/bedDetails/${bed_id}`, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });
      // Assuming you want to store this data for display
      setBedDetails(response.data); // You need to define setBedDetails and bedDetails state
    } catch (error) {
      console.error("Failed to fetch bed details:", error);
      setError("Failed to fetch bed details. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAvailableBeds = async (bedCount) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${BE_URL}/api/hostelBeds?gender=${gender}`,
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );
      setAvailableBeds(response.data);
    } catch (error) {
      console.error("Error fetching available beds:", error);
      // Handle error appropriately
    } finally {
      setIsLoading(false);
    }
  };

  const fetchMessageById = async (studentId) => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.get(
        `${BE_URL}/api/students/message/${studentId}`,
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );
      setmessages(response.data);
    } catch (error) {
      if (!error.response.status === 404) {
        console.error("Failed to fetch messages:", error);
        setError("Failed to fetch messages. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleAssignBed = async (bedId) => {
    setIsLoading(true);
    try {
      await axios.post(
        `${BE_URL}/api/hostels/beds/assign`,
        {
          bed_id: bedId,
          student_id: id,
          student_roll_no: roll_no,
        },
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );
      alert("Bed assigned successfully.");
      fetchAvailableBeds(selectedBedCount); // Refresh available beds
      fetchStudentDetails();
    } catch (error) {
      console.error("Error assigning bed:", error);
      // Handle error appropriately
    } finally {
      setIsLoading(false);
    }
  };
  const handleSendMessage = async (details) => {
    setIsLoading(true);

    console.log("msg details", details.sendOnWhatsapp);
    try {
      await axios.post(
        `${BE_URL}/api/students/message`,
        {
          message: details.message,
          student_id: id,
          send_on_whatsapp: details.sendOnWhatsapp,
        },
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );
      alert("Message sent successfully.");
      fetchAvailableBeds(selectedBedCount); // Refresh available beds
      fetchStudentDetails();
    } catch (error) {
      console.error("Error assigning bed:", error);
      // Handle error appropriately
    } finally {
      setIsLoading(false);
    }
  };

  // Trigger this function when selectedBedCount changes
  useEffect(() => {
    if (selectedBedCount) {
      fetchAvailableBeds(selectedBedCount);
    }
  }, [selectedBedCount]);

  useEffect(() => {
    if (!hostel) {
      setSelectedBedCount(null);
    }
  }, [hostel]);

  const fetchPrices = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const { data } = await axios.get(`${BE_URL}/api/prices`, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });

      const messData = data
        .filter((item) => item.type === "Mess")
        .map((item) => ({
          id: item.id,
          name: `${item.service} - Rs${item.price_per_month}`,
          price: item.price_per_month,
        }));

      const transportData = data
        .filter((item) => item.type === "Transport")
        .map((item) => ({
          id: item.id,
          name: `${item.service} - Rs${item.price_per_month}`,
          price: item.price_per_month,
        }));

      setmessOptions(messData);
      settransportOptions(transportData);
    } catch (err) {
      console.error("Error fetching prices:", err);
      alert("Failed to load prices.");
    }
  };

  const handleMessSelection = (selectedId) => {
    setmessPriceId(selectedId);
    const selectedOption = messOptions.find(
      (option) => option.id === selectedId
    );
    console.log("Selected option is ", selectedOption);
    setSelectedMessPrice(selectedOption ? selectedOption.price : "");
  };

  const handleTransportSelection = (selectedId) => {
    settransportPriceId(selectedId);
    const selectedOption = transportOptions.find(
      (option) => option.id === selectedId
    );
    setSelectedTransportPrice(selectedOption ? selectedOption.price : "");
  };

  useEffect(() => {
    fetchClasses();
    fetchfeeStructures();
    fetchPrices();
  }, []);

  useEffect(() => {
    if (selectedClassId) {
      setDivision(null);
      setSelectedDivisionId(null);
      fetchDivisions(selectedClassId);
    }
  }, [selectedClassId]);

  const fetchClasses = async () => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.get(`${BE_URL}/api/classes`, {
        headers: {
          Authorization: `Bearer ${tokens.accessToken}`, // Use the access token for authorization
        },
      });
      setClasses(response.data); // Assuming the API returns an array of class objects
    } catch (error) {
      console.error("Error fetching classes:", error);
      // Handle specific errors (e.g., unauthorized, server issues)
      if (error.response && error.response.status === 401) {
        // Handle unauthorized error, e.g., redirect to login or refresh token
        alert("Session expired. Please login again.");
      } else {
        alert("Failed to fetch classes. Please try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDivisions = async (id) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${BE_URL}/api/general/class/${id}/divisions`,
        {}
      );
      setDivisions(response.data);
    } catch (error) {
      console.error("Error fetching classes:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchfeeStructures = async () => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.get(`${BE_URL}/api/feeStrAdmission`, {
        headers: {
          Authorization: `Bearer ${tokens.accessToken}`, // Use the access token for authorization
        },
      });
      setfeeStructures(response.data); // Assuming the API returns an array of hostel pricing objects
    } catch (error) {
      console.error("Error fetching hostel pricing:", error);
      // Handle specific errors similarly as done in fetchClasses
      if (error.response && error.response.status === 401) {
        alert("Session expired. Please login again.");
      } else {
        alert("Failed to fetch hostel pricing. Please try again later.");
      }
    } finally {
      setIsLoading(false); // Ensure loading state is updated in finally block
    }
  };

  const handleRefresh = () => {
    fetchStudentDetails();
  };

  const fetchStudentDetails = async () => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const url = is_senior
        ? `${BE_URL}/api/students/${roll_no}`
        : `${BE_URL}/api/students/junior/${roll_no}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${tokens.accessToken}`,
        },
      });

      if (response.data) {
        const scholarship = response.data;

        // Parse the date and extract only the date part
        const dob = new Date(scholarship.date_of_birth);
        const formattedDob = dob.toISOString().split("T")[0];

        setid(scholarship.id);
        setuserId(scholarship.user_id);
        setrollnumber(scholarship.roll_no);
        setOMRrollnumber(scholarship.omr_roll_no);
        setFirstname(scholarship.first_name);
        setmiddlename(scholarship.middle_name);
        setlastname(scholarship.last_name);
        setdob(formattedDob);
        setfathername(scholarship.fathers_name);
        setmothername(scholarship.mothers_name);
        setemail(scholarship.email);
        setwhatsapp(scholarship.whatsapp);
        setAlternateNo(scholarship.alternate_no);
        setaadhar(scholarship.aadhar);
        setgender(scholarship.gender);
        setstream(scholarship.stream);
        setbloodgroup(scholarship.blood_group);
        setadmclass(scholarship.adm_class);
        sethostel(scholarship.hostel);
        setphoto_url(scholarship.photo_url);

        setDivision(scholarship.division);
        setSelectedDivisionId(scholarship.division_id);

        setaddress(scholarship.address);
        setdistrict(scholarship.district);
        settaluka(scholarship.taluka);
        setpin(scholarship.pin);
        setcancelled(scholarship.is_cancelled);
        setcancellationTime(scholarship.cancellation_time);

        setscholarshipAmount(scholarship.scholarship_amt);
        setAdditionalAmount(scholarship.additial_amount);
        setBed_id(scholarship.bed_id);
        setSelectedBedCount(scholarship.bed_count);
        setadditional_subject(
          scholarship.additional_subject_name ? true : false
        );
        setSelectedAdditionalSubject(scholarship.additional_subject_name);

        setyear_start(scholarship.academic_year_start);
        setyear_end(scholarship.academic_year_end);

        setMessRequired(scholarship.mess);
        setmessFee(scholarship.messfees);
        setMessMonths(scholarship.messmonths);
        setmessPriceId(scholarship.messpriceid);
        setMessValidityDate(formatDate(scholarship.messvalidity));
        setTransportRequired(scholarship.transport);
        setTransportMonths(scholarship.transportmonths);
        settransportFee(scholarship.transportfees);
        settransportPriceId(scholarship.transportpriceid);
        setTransportValidityDate(formatDate(scholarship.transportvalidity));

        setReducedTotal(scholarship.discounted_fee);
        setTotalFees(scholarship.total_fee);
        setprev_roll_no(scholarship.prev_roll_no);
        setinstallments(scholarship.installments);
        setoriginalInstallments(scholarship.installments);

        const leavesresponse = await axios.get(
          `${BE_URL}/api/general/userLeaves/${scholarship.user_id}`,
          {
            headers: {
              Authorization: `Bearer ${tokens.accessToken}`, // Use the access token for authorization
            },
          }
        );
        setleaves(leavesresponse.data);
        fetchMessageById(scholarship.id);
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Handle token expiry
        setIsLoading(false);
        const newTokens = await refreshToken();
        if (newTokens) {
          fetchStudentDetails(); // Retry fetching details with new token
        } else {
          // Redirect to login or show an error
          alert("User logged out.");
          // navigate("/login");
        }
      } else {
        console.error("Error fetching scholarship details:", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const validateForm = () => {
    const newErrors = {};

    const requiredFields = [
      "firstname",
      "middlename",
      "lastname",
      "fathername",
      "dob",
      "mothername",
      "email",
      "whatsapp",
      "alternateNo",
      "aadhar",
      "gender",
      "bloodgroup",
      "admclass",
      "address",
      "district",
      "taluka",
      "pin",
      "year_start",
    ];

    for (let field of requiredFields) {
      if (!eval(field)) {
        newErrors[field] = "This field is required";
      }
    }

    // New validations
    if (whatsapp && whatsapp.length !== 10) {
      newErrors.whatsapp = "WhatsApp number must be 10 digits";
    }

    if (alternateNo && alternateNo.length !== 10) {
      newErrors.alternateNo = "Alternate number must be 10 digits";
    }

    if (aadhar && aadhar.length !== 12) {
      newErrors.aadhar = "Aadhar number must be 12 digits";
    }

    if (pin && pin.length !== 6) {
      newErrors.pin = "Pin must be 6 digits";
    }

    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      setError(null);
      return true;
    } else {
      setError("Please recheck the fields marked in red.");
      console.log(newErrors);
      setIsLoading(false);
      return false;
    }
  };

  const formatDate = (date) => {
    const newdate = new Date(date);
    return newdate.toISOString().split("T")[0];
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      alert("Please correct the errors before submitting.");
      return;
    }

    if (!admclass) {
      alert("Please select the class");
      return;
    }
    if (!selectedDivisionId) {
      alert("Please select the Division");
      return;
    }
    if (hostel && !selectedBedCount) {
      alert("Please select the bed count.");
      return;
    }

    if (!window.confirm("Are you sure you want to save the changes?")) {
      return;
    }

    setIsLoading(true);
    setError(null);

    const studentData = {
      // Gather all necessary fields from state
      id: id,
      is_senior: is_senior,
      prev_roll_no: roll_no,
      first_name: firstname,
      middle_name: middlename,
      last_name: lastname,
      date_of_birth: dob,
      fathers_name: fathername,
      mothers_name: mothername,
      email: email,
      whatsapp: whatsapp,
      alternate_no: alternateNo,
      aadhar: aadhar,
      gender: gender,
      stream: stream,
      blood_group: bloodgroup,
      adm_class: admclass.match(/\d+/)[0],
      class_id: selectedClassId, // Ensure this is the actual class ID
      division: division,
      division_id: selectedDivisionId,
      academic_year_start: year_start,
      academic_year_end: year_end,
      hostel: hostel,
      scholarship_amt: scholarshipAmount,
      additial_amount: additionalAmount > 0 ? additionalAmount : null,
      total_fee: totalFees,
      class_fee: classFee,
      discounted_fee: reducedTotal,
      hostel_fees: hostelFee,
      bed_count: hostel ? selectedBedCount : null,
      academic_year_start: year_start,
      academic_year_end: year_end,
      mess: messRequired,
      messMonths: messRequired ? messMonths : null,
      messFees: messRequired ? messFee : null,
      messValidity: messRequired ? messValidityDate : null,
      messPriceId: messRequired ? messPriceId : null,
      transport: transportRequired,
      transportMonths: transportRequired ? transportMonths : null,
      transportFees: transportRequired ? transportFee : null,
      transportPriceId: transportRequired ? transportPriceId : null,
      transportValidity: transportRequired ? transportValidityDate : null,
      address: address,
      district: district,
      taluka: taluka,
      pin: pin,
      updateInstallments: false,
      installments: null,
      additional_subject: additional_subject,
      additional_subject_name: additional_subject
        ? additional_subject_name
        : null,
      // Include any other fields you have in your form
    };

    try {
      const url = is_senior
        ? `${BE_URL}/api/students/${roll_no}`
        : `${BE_URL}/api/students/junior/${roll_no}`;
      const response = await axios.put(url, studentData, {
        headers: {
          Authorization: `Bearer ${tokens.accessToken}`,
        },
      });
      if (response.status === 200) {
        alert("Student data updated successfully!");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Token expired, attempt to refresh
        const refreshed = await refreshToken();
        if (refreshed) {
          handleSubmit();
        } else {
          navigate("/login");
        }
      } else {
        console.error("Failed to submit form:", error);
        setError("Failed to create student. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const refreshToken = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await fetch(`${BE_URL}/refreshToken`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: tokens.refreshToken }),
      });

      if (!response.ok) {
        throw new Error("Failed to refresh token");
      }

      const newTokens = await response.json();
      localStorage.setItem("ERPTokens", JSON.stringify(newTokens));
      return newTokens;
    } catch (error) {
      console.error("Error refreshing token:", error);
      // alert("User loggod out. Please login again");
      // navigate("/login");
      // return null;
    }
  };

  const handleDelete = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const url = is_senior
        ? `${BE_URL}/api/students/${roll_no}`
        : `${BE_URL}/api/students/junior/${roll_no}`;
      await axios.delete(url, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });
      alert("Student deleted Successfully");
      navigate(is_senior ? "/admin/seniorStudents" : "/admin/juniorStudents");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return handleDelete();
        } else {
          setError("Authentication failed. Please log in again.");
        }
      } else if (error.response && error.response.status === 409) {
        console.log(error.response);
        alert(
          "Deletion request denied! Reason - Student has paid fees. Please proceed to cancel the student admission and refund."
        );
      } else {
        setError(error.message || "Failed to delete subject.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const cancelStudent = async () => {
    if (!window.confirm("Are you sure you want to cancel this student?")) {
      return;
    }

    const refundAmount = prompt(
      "Please enter the refund amount for cancellation (enter 0 if not applicable):"
    );
    if (refundAmount === null) {
      return;
    }

    setIsLoading(true);
    try {
      const url = is_senior
        ? `${BE_URL}/api/students/cancel/${id}`
        : `${BE_URL}/api/students/junior/cancel/${id}`;
      const response = await axios.put(
        url,
        { refundAmount }, // Send refundAmount to the backend
        {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        alert(`Student cancelled successfully! `);
      }
      fetchStudentDetails();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        try {
          const newAccessToken = await refreshToken();
          if (newAccessToken) {
            await cancelStudent(id); // Retry the API call with the new token
          }
        } catch (refreshError) {
          setError("Session expired. Please log in again.");
        }
      } else {
        console.error("Failed to cancel student:", error);
        setError("Failed to cancel student. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const deallocateBedFromStudent = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      if (!tokens || !tokens.accessToken) {
        throw new Error("Authentication token not found");
      }

      const response = await axios.post(
        `${BE_URL}/api/hostels/beds/deassign`,
        {
          bed_id: bed_id,
          student_id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        }
      );
      fetchStudentDetails();

      console.log("Bed deallocated successfully:", response.data);
      return response.data; // or handle response data as needed
    } catch (error) {
      console.error("Error deallocating bed from student:", error);
      console.error("Error deallocating bed from student:", error);
      // Handle errors, e.g., show notification or error message
      if (error.response) {
        // Server responded with a status code outside the 2xx range
        console.error("Failed to deallocate bed:", error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No response received:", error.request);
      } else {
        // Something else caused the error
        alert("Something went wrong, please try again later.");
        console.error("Error:", error.message);
      }
      throw error; // Re-throw to let the calling component handle it
    }
  };

  const reactivateStudent = async () => {
    if (!window.confirm("Are you sure you want to reactivate this student?")) {
      return;
    }
    setIsLoading(true);
    try {
      const url = is_senior
        ? `${BE_URL}/api/students/reactivate/${id}`
        : `${BE_URL}/api/students/junior/reactivate/${id}`;
      const response = await axios.put(
        url,
        {
          first_name: firstname,
          middle_name: middlename,
          last_name: lastname,
          email,
          whatsapp,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        alert("Student reactivated successfully!");
      }
      fetchStudentDetails();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        try {
          const newAccessToken = await refreshToken();
          if (newAccessToken) {
            await reactivateStudent(id); // Retry the API call with the new token
          }
        } catch (refreshError) {
          setError("Session expired. Please log in again.");
          // Handle logout and redirection to login page here
        }
      } else {
        console.error("Failed to reactivate student:", error);
        setError("Failed to reactivate student. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const selectDistrict = (val) => {
    setdistrict(val);
    const obj = data.find((city) => city.name === val);
    settalukas(obj.tahasil);
  };

  const monthsOptions = Array.from({ length: 11 }, (_, i) => ({
    label: `${i + 1}`, // Display numbers starting from 1 to 11 as string
    value: i + 1, // Actual value is the month number from 1 to 11
  }));

  // Handle file change
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  // Handle Photo Change/Upload
  const handleFileUpload = async () => {
    if (!file) {
      alert("Please select a file first.");
      return;
    }

    const formData = new FormData();
    formData.append("photo", file);
    formData.append("type", "students");

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens")); // Ensure you have tokens stored in localStorage
      const response = await axios.post(
        `${BE_URL}/updateStudentPhoto/${id}/`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`, // Authorization header
            "Content-Type": "multipart/form-data", // Required for file uploads
          },
        }
      );

      setphoto_url(response.data.photoUrl); // Update photo with new URL
      alert("Photo updated successfully!");
    } catch (error) {
      console.error("Error uploading photo:", error);
      alert("Failed to upload photo. Please try again.");
    }
  };

  // Handle download photo
  const handleDownloadPhoto = () => {
    const link = document.createElement("a");
    link.href = photo_url; // Use the photo URL to download
    link.download = `${rollnumber}_${firstname}_${lastname}_photo.jpg`; // File name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleEditFees = (type) => {
    if (type === "Cancel") {
      setfeeEdit(false);
    } else {
      setfeeEdit(true);
      setinstallments(originalInstallments);
    }
  };

  const formatDateOnly = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleDateString();
    // const formattedTime = date.toLocaleTimeString();
    // return `${formattedDate} ${formattedTime}`;
    return `${formattedDate}`;
  };
  const formatDateAndTime = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();
    return `${formattedDate} ${formattedTime}`;
  };

  return (
    <div className="sd">
      <Modal
        isOpen={isImageModalOpen}
        onClose={() => setIsImageModalOpen(false)}
      >
        <div className="sd-imgChange">
          <input type="file" onChange={handleFileChange} accept="image/*" />
          <br />
          <Button
            type={`primary`}
            text={`Change Photo`}
            onClick={handleFileUpload}
          ></Button>
        </div>
      </Modal>
      <Modal isOpen={isStMsgOpen} onClose={() => setIsStMsgOpen(false)}>
        <MessageSender onSend={(details) => handleSendMessage(details)} />
      </Modal>
      {permissions?.can_read ? (
        <>
          {isLoading && <Loader />}
          {!cancelled && hostel && !bed_id && (
            <div className="sd-warn">
              {" "}
              &#9432; Hostel not allocated. Please scroll down and assign
              hostel.
            </div>
          )}
          {cancelled && (
            <div className="sd-warn">
              {" "}
              &#9432; This admission is cancelled. <br /> Cancellation Ticket No
              - cancel_{roll_no}
              <span>
                {" "}
                <br />
                Cancelled on - {formatDateOnly(cancellationTime)}{" "}
              </span>
            </div>
          )}

          <div className="sd-header">
            <div className="sd-header-meta">
              <div className="sd-header-meta-img">
                <img
                  src={
                    photo_url
                      ? photo_url
                      : "https://erpresources.s3.ap-south-1.amazonaws.com/profile.png"
                  }
                  alt="Student Image"
                />
                <div className="img-buttons">
                  <button onClick={() => setIsImageModalOpen(true)}>
                    Change
                  </button>
                  {photo_url && (
                    <button onClick={handleDownloadPhoto}>Download</button>
                  )}
                </div>
              </div>

              <div className="sd-header-meta-details">
                <div className="sd-header-meta-details-info">
                  <div className="sd-header-meta-details-name">{`${firstname} ${middlename} ${lastname}`}</div>
                  <div className="sd-header-meta-details-email">{email}</div>
                  <div className="sd-header-meta-details-whatsapp">
                    Ph. {whatsapp}
                  </div>
                  <div className="sd-header-meta-details-roll">
                    Roll No. {rollnumber}
                  </div>
                </div>
                <div className="sd-header-meta-details-cta">
                  {permissions?.can_update && !cancelled ? (
                    <Button
                      type={`minired`}
                      text={`Cancel`}
                      onClick={cancelStudent}
                    />
                  ) : (
                    ""
                  )}
                  {permissions?.can_update && cancelled ? (
                    <Button
                      type={`minigreen`}
                      text={`Reactivate`}
                      onClick={reactivateStudent}
                    />
                  ) : (
                    ""
                  )}
                  <Button
                    type={`minired`}
                    text={`Delete`}
                    onClick={handleDelete}
                  />
                </div>
              </div>
            </div>

            <div className="sd-header-msgs">
              <div className="sd-header-msgs-title">Messages</div>
              <div className="sd-header-msgs-list">
                {messages.map((msg) => (
                  <div className="msg-item" key={msg.id}>
                    <div className="msg-item-meta">
                      <div className="msg-item-meta-name">{msg.user_name}</div>
                      <div className="msg-item-meta-time">
                        {formatDateAndTime(msg.sent_at)}
                      </div>
                    </div>
                    <div className="msg-item-meta-msg">{msg.message}</div>
                  </div> // Added key for React optimization
                ))}
              </div>

              <div className="sd-header-msgs-sender">
                <MessageSender
                  onSend={(details) => handleSendMessage(details)}
                />
              </div>
              {/* <Button
                type={`primary`}
                text={`Message`}
                onClick={() => setIsStMsgOpen(true)}
              /> */}
            </div>
          </div>

          <div ref={printDivRef} className="sd-contents">
            {/* <div className="sd-contents-title">Roll No: {rollnumber}</div> */}
            <div className="sd-contents-section">
              <div className="sd-contents-section-title">Basic Information</div>
              <div className="sd-contents-section-fields">
                <div
                  className={`sd-contents-section-fields-field ${
                    errors.firstname ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`First Name`}
                    value={firstname}
                    onChange={setFirstname}
                  />
                </div>
                <div
                  className={`sd-contents-section-fields-field ${
                    errors.middlename ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`Middle Name`}
                    value={middlename}
                    onChange={setmiddlename}
                  />
                </div>
                <div
                  className={`sd-contents-section-fields-field ${
                    errors.lastname ? "invalid" : ""
                  }`}
                >
                  <TextField
                    className={errors.lastname ? "input-error" : ""}
                    placeholder={`Last Name`}
                    value={lastname}
                    onChange={setlastname}
                  />
                </div>
                <div className="sd-contents-section-fields-field">
                  <DatePicker
                    invalid={errors.dob}
                    label={`Date of Birth`}
                    value={dob}
                    onChange={setdob}
                  />
                </div>
                <div
                  className={`sd-contents-section-fields-field ${
                    errors.fathername ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`Father's Name`}
                    value={fathername}
                    onChange={setfathername}
                  />
                </div>
                <div
                  className={`sd-contents-section-fields-field ${
                    errors.mothername ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`Mother's Name`}
                    value={mothername}
                    onChange={setmothername}
                  />
                </div>
                <div
                  className={`sd-contents-section-fields-field ${
                    errors.email ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`Email`}
                    value={email}
                    onChange={setemail}
                  />
                </div>
                <div
                  className={`sd-contents-section-fields-field ${
                    errors.whatsapp ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`WhatsApp No.`}
                    value={whatsapp}
                    onChange={setwhatsapp}
                  />
                </div>
                <div
                  className={`sd-contents-section-fields-field ${
                    errors.alternateNo ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`Alternate No.`}
                    value={alternateNo}
                    onChange={setAlternateNo}
                  />
                </div>
                <div
                  className={`sd-contents-section-fields-field ${
                    errors.aadhar ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`Aadhar No.`}
                    value={aadhar}
                    onChange={setaadhar}
                  />
                </div>
                <div className={`sd-contents-section-fields-field`}>
                  <DropDown
                    invalid={errors.year_start}
                    options={academic_years}
                    label={`Academic Year`}
                    value={year_start}
                    onChange={setyear_start}
                  />
                </div>
                {/* <div
                  className={`sd-contents-section-fields-field ${
                    errors.year_start ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`Academic Year Start`}
                    value={year_start}
                    onChange={setyear_start}
                  />
                </div> */}
                {/* <div
                  className={`sd-contents-section-fields-field ${
                    errors.year_end ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`Academic Year End`}
                    value={year_end}
                    onChange={setyear_end}
                  />
                </div> */}
                <div className="sd-contents-section-fields-field">
                  <DropDown
                    invalid={errors.gender}
                    options={genders}
                    label={`Gender`}
                    value={gender}
                    onChange={setgender}
                  />
                </div>
                <div className="sd-contents-section-fields-field">
                  <DropDown
                    invalid={errors.stream}
                    options={streams}
                    label={`stream`}
                    value={stream}
                    onChange={setstream}
                  />
                </div>
                <div className="sd-contents-section-fields-field">
                  <DropDown
                    invalid={errors.bloodgroup}
                    options={bloodgroups}
                    label={`Blood Group`}
                    value={bloodgroup}
                    onChange={setbloodgroup}
                  />
                </div>
                {/* <div className="sd-contents-section-fields-field"></div> */}
              </div>
            </div>

            <hr className="sd-contents-seperator"></hr>

            {/* Address */}
            <div className="sd-contents-section">
              <div className="sd-contents-section-title">Address</div>
              <div className="sd-contents-section-fields">
                <div
                  className={`sd-contents-section-fields-field ${
                    errors.address ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`Address`}
                    value={address}
                    onChange={setaddress}
                  />
                </div>
                <div className="sd-contents-section-fields-field">
                  <DropDown
                    invalid={errors.district}
                    options={districts}
                    label={`District`}
                    value={district}
                    onChange={selectDistrict}
                  />
                </div>
                <div className="sd-contents-section-fields-field">
                  <DropDown
                    invalid={errors.taluka}
                    options={talukas}
                    label={`Taluka`}
                    value={taluka}
                    onChange={settaluka}
                  />
                </div>
                <div
                  className={`sd-contents-section-fields-field ${
                    errors.pin ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`Pin`}
                    value={pin}
                    onChange={setpin}
                  />
                </div>
                <div className="sd-contents-section-fields-field"></div>
              </div>
            </div>

            <hr className="sd-contents-seperator"></hr>

            <div className="sd-contents-section">
              <div className="sd-contents-section-title">Mess & Transport</div>
              <div className="sd-contents-section-fields">
                <div className="sd-contents-section-fields-field">
                  <ObjectDropDown
                    label="Mess Taken"
                    options={[
                      { value: "Yes", label: true },
                      { value: "No", label: false },
                    ].map(({ label, value }) => ({
                      label: label,
                      value: value,
                    }))}
                    value={messRequired}
                    setValue={(val) =>
                      val === "Yes"
                        ? setMessRequired(true)
                        : setMessRequired(false)
                    }
                    setId={() => {}}
                  />
                </div>

                {messRequired && (
                  <>
                    <div className="sd-contents-section-fields-field">
                      <CustomDropDown
                        label="Mess Type"
                        data={messOptions}
                        value={messPriceId}
                        onChange={handleMessSelection}
                        searchable={false}
                      />
                    </div>
                    <div className="sd-contents-section-fields-field">
                      <ObjectDropDown
                        label="Number of Months"
                        options={monthsOptions}
                        value={messMonths}
                        setId={() => {}}
                        setValue={setMessMonths}
                      />
                    </div>
                    <div className="sd-contents-section-fields-field">
                      <DatePicker
                        label="Mess Validity Date"
                        value={messValidityDate}
                        onChange={setMessValidityDate}
                      />
                    </div>
                  </>
                )}
                <div className="sd-contents-section-fields-field">
                  <ObjectDropDown
                    label="Transport taken"
                    options={[
                      { value: "Yes", label: true },
                      { value: "No", label: false },
                    ].map(({ label, value }) => ({
                      label: label,
                      value: value,
                    }))}
                    value={transportRequired}
                    setValue={(val) =>
                      val === "Yes"
                        ? setTransportRequired(true)
                        : setTransportRequired(false)
                    }
                    setId={() => {}}
                  />
                </div>
                {transportRequired && (
                  <>
                    <div className="sd-contents-section-fields-field">
                      <CustomDropDown
                        label="Transport Type"
                        data={transportOptions}
                        value={transportPriceId}
                        onChange={handleTransportSelection}
                      />
                    </div>
                    <div className="sd-contents-section-fields-field">
                      <ObjectDropDown
                        label="Number of Months"
                        options={monthsOptions}
                        value={transportMonths}
                        setId={() => {}}
                        setValue={setTransportMonths}
                      />
                    </div>
                    <div className="sd-contents-section-fields-field">
                      <DatePicker
                        label="Transport Validity Date"
                        value={transportValidityDate}
                        onChange={setTransportValidityDate}
                      />
                    </div>
                  </>
                )}

                {/* <div>
                  {messRequired && messMonths ? (
                    <div className="sd-contents-section-fees">
                      Mess fees is {messFee}. Add it to the installments
                      manually.
                    </div>
                  ) : null}
                </div>
                {transportRequired && transportMonths ? (
                  <div className="sd-contents-section-fees">
                    Transport fees is {transportFee}. Add it to the installments
                    manually.
                  </div>
                ) : null} */}
              </div>
            </div>

            <hr className="sd-contents-seperator"></hr>

            {/* Admission Related Information */}
            <div className="sd-contents-section">
              <div className="sd-contents-section-title">
                Admission Related Information
              </div>
              <div className="sd-contents-section-fields">
                {/* <div className="sd-contents-section-fields-field">
                  <CustomNumberInput
                    value={additionalAmount}
                    label={`Additional Discount`}
                    placeholder={`Additional discount`}
                    onChange={(e) => setAdditionalAmount(e)}
                  />
                </div> */}
                {/* <div className="sd-contents-section-fields-field">
                  <CustomNumberInput
                    value={additionalAmount}
                    label={`Additional Discount`}
                    placeholder={`Additional discount`}
                    onChange={(e) => setAdditionalAmount(e)}
                  />
                </div> */}
                <div className="sd-contents-section-fields-field">
                  <ObjectDropDown
                    label="Class"
                    options={classes.map(({ class_name, id }) => ({
                      label: id,
                      value: class_name,
                    }))}
                    value={admclass}
                    setId={setSelectedClassId}
                    setValue={setadmclass}
                  />
                </div>
                <div className="sd-contents-section-fields-field">
                  <ObjectDropDown
                    label="Division"
                    options={divisions.map(({ name, id }) => ({
                      label: id,
                      value: name,
                    }))}
                    value={division}
                    setId={setSelectedDivisionId}
                    setValue={setDivision}
                  />
                </div>
                <div className="sd-contents-section-fields-field">
                  <ObjectDropDown
                    label="Opted for Hostel"
                    options={[
                      { value: "Yes", label: true },
                      { value: "No", label: false },
                    ].map(({ label, value }) => ({
                      label: label,
                      value: value,
                    }))}
                    value={hostel ? "Yes" : "No"}
                    setId={() => {}}
                    setValue={(val) =>
                      val === "Yes" ? sethostel(true) : sethostel(false)
                    }
                  />
                </div>
                {hostel && (
                  <div className="sd-contents-section-fields-field">
                    <ObjectDropDown
                      label="Bed Count"
                      options={[
                        { value: 4, label: 4 },
                        { value: 5, label: 5 },
                        { value: 6, label: 6 },
                        { value: "hostel", label: "hostel" },
                      ].map(({ label, value }) => ({
                        label: label,
                        value: value,
                      }))}
                      value={selectedBedCount}
                      setValue={setSelectedBedCount}
                      setId={() => {}}
                    />
                  </div>
                )}

                {is_senior && (
                  <>
                    <div className="sd-contents-section-fields-field">
                      <ObjectDropDown
                        label="Additional Subject?"
                        options={[
                          { value: "Yes", label: true },
                          { value: "No", label: false },
                        ].map(({ label, value }) => ({
                          label: label,
                          value: value,
                        }))}
                        value={additional_subject}
                        setValue={(val) =>
                          val === "Yes"
                            ? setadditional_subject(true)
                            : setadditional_subject(false)
                        }
                        setId={() => {}}
                      />
                    </div>
                    {additional_subject && (
                      <div className="sd-contents-section-fields-field">
                        <ObjectDropDown
                          label="Additional Subject Name"
                          options={[
                            { value: "I.T.", label: "I.T." },
                            { value: "Crop Science", label: "Crop Science" },
                          ].map(({ label, value }) => ({
                            label: label,
                            value: value,
                          }))}
                          value={additional_subject_name}
                          setValue={setadditional_subject_name}
                          setId={() => {}}
                        />
                      </div>
                    )}
                  </>
                )}

                {/* Displaying Class Fee and Installments */}
              </div>
            </div>

            <div className="sd-contents-section">
              <div className="sd-contents-section-buttons">
                {feeEdit ? (
                  <Button
                    text={`Cancel`}
                    type={"argreen"}
                    onClick={() => handleEditFees("Cancel")}
                  />
                ) : (
                  <Button
                    text={`Edit Fees`}
                    type={"arred"}
                    onClick={() => handleEditFees("Edit")}
                  />
                )}
              </div>
              {installments && installments.length > 0 && feeEdit ? (
                <div className="sd-contents-section-fields-inst">
                  <InstallmentsStudentDetailsEdit
                    session={year_start}
                    student_id={id}
                    student_roll_no={roll_no}
                    title="Class Fee Details"
                    fee={totalFees}
                    installments={installments}
                    setInstallments={setinstallments}
                    scholarshipAmount={scholarshipAmount}
                    additial_amount={additionalAmount}
                    finalFee={reducedTotal}
                    handleRefresh={handleRefresh}
                  />
                </div>
              ) : (
                feeEdit && "No Fees structure found for the current selections."
              )}
              {!feeEdit && (
                <StudentFee
                  admin={true}
                  adminStudentDetails={{
                    total_fee: totalFees,
                    scholarship_amount: scholarshipAmount,
                    additial_amount: additionalAmount,
                    final_fee: reducedTotal,
                  }}
                  cancelled={cancelled}
                  adminInstallments={originalInstallments}
                  handleRefresh={handleRefresh}
                />
              )}
            </div>

            {error && <div className="sd-error">{error}</div>}
            {!cancelled && permissions?.can_update && (
              <div className="sd-bottom">
                <Button
                  text="Promote"
                  type="argreen"
                  onClick={() => {
                    navigate(`/admin/admission-form/${roll_no}`, {
                      state: {
                        is_senior: Number(admclass) < 10 ? false : true,
                        promotion: true,
                      },
                    });
                  }}
                />

                {/* <div className="sd-button"> */}
                <Button text={`Save Changes`} onClick={handleSubmit} />
                {/* </div> */}
              </div>
            )}
          </div>

          {/* <div>Prev Roll No. {prev_roll_no}</div> */}

          {!cancelled && (
            <>
              {hostel && !bed_id ? (
                <div className="sd-hostel">
                  {/* Hostel Allotment Section */}
                  <div className="hostel-assignment">
                    {hostel && availableBeds.length > 0 && (
                      <>
                        <h3>Available Beds</h3>
                        <div className="available-beds">
                          {availableBeds.map((bed) => (
                            <div key={bed.bed_id} className="bed-details">
                              <span>{`Hostel: ${bed.hostel_name} Floor: ${bed.floor_number}`}</span>
                              <span>{`Room: ${bed.room_number} Bed: ${bed.bed_number}`}</span>
                              <Button
                                text="Assign Bed"
                                onClick={() => handleAssignBed(bed.bed_id)}
                              />
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ) : (
                <div className="sd-hostel">
                  {/* Bed Details Section */}
                  {hostel && bedDetails && (
                    <div className="hostelDetails">
                      <h3>Hostel Details</h3>
                      <div className="hostelDetails-cards">
                        <div className="hostelDetails-cards-card">
                          <div className="hostelDetails-cards-card-name">
                            Hostel Name
                          </div>

                          {bedDetails.hostel_name}
                        </div>
                        <div className="hostelDetails-cards-card">
                          <div className="hostelDetails-cards-card-name">
                            Floor Number
                          </div>
                          {bedDetails.floor_number}
                        </div>
                        <div className="hostelDetails-cards-card">
                          <div className="hostelDetails-cards-card-name">
                            Room Number
                          </div>
                          {bedDetails.room_number}
                        </div>
                        <div className="hostelDetails-cards-card">
                          <div className="hostelDetails-cards-card-name">
                            Bed Number
                          </div>
                          {bedDetails.bed_number}
                        </div>
                      </div>
                      <div className="hostelDetails-button">
                        <Button
                          type="arred"
                          onClick={deallocateBedFromStudent}
                          text={`DeAllocate`}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}{" "}
            </>
          )}
        </>
      ) : (
        ""
      )}

      {/* {messages && (
        <div className="sd-leaves">
          <div className="sd-leaves-title">Messages</div>
          <div className="sd-leaves-list">
            
          </div>
        </div>
      )} */}

      <div className="sd-leaves">
        <div className="sd-leaves-title">Leaves</div>
        <div className="sd-leaves-list">
          <LeavesList leaveApplications={leaves} cancel_allowed={false} />
        </div>
      </div>
      <div className="sd-invent">
        <div className="sd-invent-title">Assigned Inventory</div>
        <div className="sd-invent-list">
          {userId && (
            <AssignedInventory type={`student`} admin={true} id={userId} />
          )}
        </div>
      </div>
      <div className="sd-Result">
        {/* <div className="sd-Result-title">Result</div> */}
        <div className="sd-Result-list">
          {userId && <StudentResults is_admin={true} user={userId} />}
        </div>
      </div>
    </div>
  );
};

export default StudentDetails;

const MessageSender = ({ onSend }) => {
  const [message, setMessage] = useState("");
  const [sendOnWhatsapp, setSendOnWhatsapp] = useState(false);

  const handleSend = () => {
    if (!message.trim()) {
      alert("Message cannot be empty");
      return;
    }

    onSend({ message, sendOnWhatsapp });

    setMessage(""); // Clear the message box
    setSendOnWhatsapp(false); // Reset the checkbox
  };

  return (
    <div className="message-sender">
      {/* Message Input */}
      <input
        type="text"
        placeholder="Enter your message..."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        className="message-input"
      />

      {/* WhatsApp Checkbox */}
      <label className="whatsapp-checkbox">
        <input
          type="checkbox"
          checked={sendOnWhatsapp}
          onChange={(e) => setSendOnWhatsapp(e.target.checked)}
        />
        WhatsApp
      </label>

      {/* Send Button */}
      <button className="send-button" onClick={handleSend}>
        Send
      </button>
    </div>
  );
};
