import React, { useState, useEffect } from "react";

import logo from "../../assets/images/logo.png";
import logoCIS from "../../assets/images/logoCIS.jpg";

import axios from "axios";
import "./StudentFee.scss";
import Button from "../Button/Button";
import Loader from "../Loader/Loader";
import Modal from "../Modal/Modal";
import ResponseModal from "../ResponseModal/ResponseModal";

import { usePDF } from "react-to-pdf";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const StudentFee = ({
  admin = false,
  handleRefresh,
  adminStudentDetails = {},
  adminInstallments = [],
  cancelled,
}) => {
  const [details, setDetails] = useState({
    studentDetails: {},
    installments: [],
  });

  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentInstallmentId, setpaymentInstallmentId] = useState(null);
  const [paymentAmount, setpaymentAmount] = useState(null);
  const [paymentName, setpaymentName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPaymentOpen, setisPaymentOpen] = useState(false);
  const [error, setError] = useState("");
  const [receiptDetails, setreceiptDetails] = useState(null);

  const [msg, setmsg] = useState(null);
  const [responseType, setresponseType] = useState(null);
  const [isResponseOpen, setisResponseOpen] = useState(false);

  const [isReceiptOpen, setisReceiptOpen] = useState(false);

  const { toPDF, targetRef } = usePDF({ filename: "receipt.pdf" });

  useEffect(() => {
    if (!admin) {
      fetchDetails();
    }
  }, []);

  const fetchDetails = async () => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.get(`${BE_URL}/api/general/studentfee`, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });
      setDetails(response.data);
      setIsLoading(false);
    } catch (err) {
      setError("Error fetching data. Please try again later.");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (admin) {
      setDetails({
        studentDetails: adminStudentDetails,
        installments: adminInstallments,
      });
    }
  }, [admin, adminStudentDetails, adminInstallments]);

  const toggleInstallmentDetails = (index) => {
    setDetails((prevState) => ({
      ...prevState,
      installments: prevState.installments.map((inst, i) => {
        if (i === index) {
          return { ...inst, isOpen: !inst.isOpen };
        }
        return inst;
      }),
    }));
  };

  const handlePayment = async (installment) => {
    setIsLoading(true);
    try {
      console.log("student details : ", details.studentDetails);
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));

      // Step 1: Initiate payment on the backend (create order)
      const response = await axios.post(
        `${BE_URL}/api/payment/initiate`,
        {
          installmentId: installment.id,
          roll_no: details.studentDetails.roll_no,
          amount: installment.final_fee,
        },
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );

      setIsLoading(false);

      // return;

      if (response.data.status === "success") {
        // Step 2: Redirect to Razorpay Hosted Checkout
        const { order_id, amount, currency, studentDetails } = response.data;

        // Create and submit a form for Razorpay Hosted Checkout
        const form = document.createElement("form");
        form.method = "POST";
        form.action = "https://api.razorpay.com/v1/checkout/embedded";

        // Helper function to add hidden inputs to the form
        const addHiddenInput = (name, value) => {
          const input = document.createElement("input");
          input.type = "hidden";
          input.name = name;
          input.value = value;
          form.appendChild(input);
        };

        // Add necessary fields to the form

        if (installment.account === "CCF") {
          addHiddenInput("key_id", process.env.REACT_APP_RAZORPAY_KEY_ID_CCF);
          addHiddenInput("name", "Chaitanya's Charitable Foundation.");
        } else if (
          installment.account === "KCEPL" ||
          installment.account === "CIS"
        ) {
          addHiddenInput("key_id", process.env.REACT_APP_RAZORPAY_KEY_ID);
          addHiddenInput("name", "Krishna Chaitanya Educare Pvt. Ltd.");
        }

        addHiddenInput("order_id", order_id);
        addHiddenInput("amount", amount);
        addHiddenInput("currency", currency);
        addHiddenInput("name", "Chaitanya's Charitable Foundation.");
        addHiddenInput("description", "Fee Payment");
        addHiddenInput(
          "image",
          "https://classerp.in/static/media/logo.6c36cc13ed9ba2894757.png"
        );
        addHiddenInput("prefill[name]", studentDetails.name);
        addHiddenInput("prefill[email]", studentDetails.email);
        addHiddenInput("prefill[contact]", studentDetails.phone);
        addHiddenInput("callback_url", `${BE_URL}/api/payment/callback`);
        // addHiddenInput("callback_url", `https://classerp.in/payment-success`);
        addHiddenInput("cancel_url", `${BE_URL}/payment/cancel`);

        // Append the form to the body and submit it
        document.body.appendChild(form);
        form.submit();
      } else {
        console.error("Failed to initiate payment:", response.data);
        alert("Failed to initiate payment. Please try again.");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error initiating payment:", error);
      alert("Error initiating payment. Please try again.");
    }
  };

  const checkPrevious = (index) => {
    if (index === 1) return true;

    console.log(details.installments);

    const prevInst = details.installments.find(
      (inst) => inst.installment_number === index - 1
    );
    if (prevInst.status === "Paid") return true;
    else return false;
  };

  const adminPayment = (id, amount, name) => {
    window.scrollTo(0, 0);
    setpaymentInstallmentId(id);
    setpaymentAmount(amount);
    setpaymentName(name);
    setisPaymentOpen(true);
  };

  // if (isLoading) return <div>Loading...</div>;
  // if (error) return <div>{error}</div>;

  //Receipt related code.
  const getReceiptDetails = async (installmentId) => {
    setIsLoading(true);
    window.scrollTo(0, 0);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.get(
        `${BE_URL}/api/receipt/${installmentId}`,
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );
      setreceiptDetails(response.data);

      setIsLoading(false);
    } catch (err) {
      setError("Error fetching receipt data. Please try again later.");
      setIsLoading(false);
    }
  };
  const closeReceiptModal = () => {
    setreceiptDetails(null);
    setisReceiptOpen(false);
  };

  useEffect(() => {
    if (receiptDetails) {
      setisReceiptOpen(true);
    }
  }, [receiptDetails]);

  const renderReceiptComponent = (receiptDetails) => {
    const { installmentResult } = receiptDetails;
    const { account } = installmentResult;
    if (account === "KCEPL") {
      return (
        <div ref={targetRef}>
          <ReceiptKCEPL details={receiptDetails} />
        </div>
      );
    }
    if (account === "CIS") {
      return (
        <div ref={targetRef}>
          <ReceiptCIS details={receiptDetails} />
        </div>
      );
    }
    if (account === "CCF") {
      return (
        <div ref={targetRef}>
          <ReceiptCCF details={receiptDetails} />
        </div>
      );
    }
    return <div>No receipt type found.</div>;
  };

  return (
    <div className="stfeedetails">
      <Modal isOpen={isReceiptOpen} onClose={() => closeReceiptModal()}>
        <div className="receipt-modal">
          <Button text={`Download`} type={`primary`} onClick={() => toPDF()} />
          <div>{receiptDetails && renderReceiptComponent(receiptDetails)}</div>
        </div>
      </Modal>
      <Modal isOpen={isPaymentOpen} onClose={() => setisPaymentOpen(false)}>
        <AcceptPayment
          installmentId={paymentInstallmentId}
          onClose={() => setisPaymentOpen(false)}
          handleRefresh={handleRefresh}
          amount={paymentAmount}
          name={paymentName}
        />
      </Modal>
      <Modal isOpen={isResponseOpen} onClose={() => setisResponseOpen(false)}>
        <ResponseModal
          type={responseType}
          msg={msg}
          onClick={() => setisResponseOpen(false)}
        />
      </Modal>
      {isLoading && <Loader />}
      <div className="stfeedetails-meta">
        <div className="stfeedetails-meta-title">Fee Summary</div>
        <div className="stfeedetails-meta-content">
          <div className="stfeedetails-meta-content-card">
            <div className="stfeedetails-meta-content-card-text">Total Fee</div>
            <div className="stfeedetails-meta-content-card-amt">
              &#8377; {details.studentDetails.total_fee}
            </div>
          </div>
          <div className="stfeedetails-meta-content-card">
            <div className="stfeedetails-meta-content-card-text">
              Scholarship Amount
            </div>
            <div className="stfeedetails-meta-content-card-amt">
              &#8377; {details.studentDetails.scholarship_amount || 0}
            </div>
          </div>
          <div className="stfeedetails-meta-content-card">
            <div className="stfeedetails-meta-content-card-text">
              Additional Discount
            </div>
            <div className="stfeedetails-meta-content-card-amt">
              &#8377; {details.studentDetails.additial_amount || 0}
            </div>
          </div>
          {/* <div className="stfeedetails-meta-content-card">
            <div className="stfeedetails-meta-content-card-text">Final Fee</div>
            <div className="stfeedetails-meta-content-card-amt">
              &#8377; {details.studentDetails.final_fee}
            </div>
          </div> */}
        </div>
      </div>

      <div className="installments-section">
        <h3>Installments</h3>
        {details.installments.map((installment, index) => (
          <>
            {installment.is_active && (
              <div key={installment.id} className="installment">
                <div
                  className="installment-header"
                  onClick={() => toggleInstallmentDetails(index)}
                >
                  <div className="installment-header-info">
                    <div className="installment-header-name">
                      {installment.installment_name}
                    </div>
                    <div
                      className={`${
                        installment.status === "Paid"
                          ? "installment-header-info-paid"
                          : "installment-header-info-unpaid"
                      }`}
                    >
                      {installment.status}
                    </div>
                  </div>
                  <span>{installment.isOpen ? "-" : "+"}</span>
                </div>
                {installment.isOpen && (
                  <div className="installment-details">
                    <div className="detail">
                      <p className="title">Due Date</p>
                      <p>
                        {new Date(installment.due_date).toLocaleDateString()}
                      </p>
                    </div>
                    <div className="detail">
                      <p className="title">Amount</p>
                      <p>{installment.amount}</p>
                    </div>
                    {installment.discount_amount ? (
                      <div className="detail">
                        <p className="title">Discount Name</p>
                        <p>{installment.discount_name || "N/A"}</p>
                      </div>
                    ) : (
                      ""
                    )}
                    {installment.discount_amount ? (
                      <div className="detail">
                        <p className="title">Discount Amount</p>
                        <p>{installment.discount_amount || 0}</p>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="detail">
                      <p className="title">Late Fees</p>
                      <p
                        className={`${
                          installment.late_fee > 0 ? "latefee" : ""
                        }`}
                      >
                        {installment.late_fee}
                      </p>
                    </div>
                    <div className="detail">
                      <strong>
                        <p className="title">Final Fees</p>
                      </strong>
                      <strong>
                        <p>{installment.final_fee}</p>
                      </strong>
                    </div>
                    {installment.status === "UnPaid" &&
                      !admin &&
                      checkPrevious(installment.installment_number) && (
                        <Button
                          onClick={() => handlePayment(installment)}
                          text={`Pay Now`}
                        />
                      )}
                    {installment.status === "UnPaid" &&
                      admin &&
                      !cancelled &&
                      checkPrevious(installment.installment_number) && (
                        <Button
                          onClick={() =>
                            adminPayment(
                              installment.id,
                              installment.final_fee,
                              installment.installment_name
                            )
                          }
                          text={`Accept Payment`}
                        />
                      )}
                    {installment.status === "Paid" && (
                      <Button
                        type={`minigreen`}
                        text={`Receipt`}
                        onClick={() => getReceiptDetails(installment.id)}
                      />
                    )}
                  </div>
                )}
              </div>
            )}
          </>
        ))}
      </div>
    </div>
  );
};

export default StudentFee;

const ReceiptCIS = ({ details }) => {
  const { installmentResult, components, paymentDetails } = details;
  const {
    first_name,
    middle_name,
    last_name,
    session,
    order_id,
    amount,
    late_fee,
    final_fee,
    adm_class,
    division,
    roll_no,
    installment_number,
    installment_name,
  } = installmentResult;

  const { created_at, payment_method, payment_id, processor_name } =
    paymentDetails;

  const formatDateAndTime = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();
    return `${formattedDate} ${formattedTime}`;
  };

  return (
    <div className="receipt-cis">
      <div className="receipt-header">
        <div className="receipt-header-logo">
          <img src={logoCIS} alt="Logo" className="logo" />
        </div>
        <div className="receipt-header-text">
          <div className="receipt-header-text-sub">
            Chaitanya Charitable Foundation's
          </div>
          <div className="receipt-header-text-name">
            CHAITANYA'S INTERNATIONAL SCHOOL
          </div>
          {/* <div className="receipt-header-text-add">
            A/P SAWAL, TAL - BARAMATI, DIST - PUNE 413133.
          </div> */}
          <div className="receipt-header-text-add">
            Gate no 43, balgude phatta, Sawal
          </div>
          <div className="receipt-header-text-add">
            Bhigwan road, Baramati 413133.
          </div>
        </div>
      </div>
      <h4 style={{ textAlign: "center" }}>Payment Receipt</h4>
      <div className="receipt-section">
        <div className="receipt-section-title">Payment Details</div>
        <table className="receipt-table">
          <tbody>
            <tr>
              <th className="th25">Student Name:</th>
              <td className="td25">
                {`${first_name} ${middle_name} ${last_name}`}
              </td>
              <th className="th25">Roll No:</th>
              <td className="td25">{roll_no}</td>
            </tr>
            <tr>
              <th className="th25">Class:</th>
              <td className="td25">{`${adm_class} - ${division}`}</td>
              <th className="th25">Session:</th>
              <td className="td25">{session}</td>
            </tr>
            <tr>
              <th className="th25">Order ID:</th>
              <td className="td25">{order_id}</td>
              <th className="th25">Transaction No:</th>
              <td className="td25">{payment_id}</td>
            </tr>
            <tr>
              <th className="th25">Date:</th>
              <td className="td25">{formatDateAndTime(created_at)}</td>
              <th className="th25">Payment Method:</th>
              <td className="td25">{payment_method}</td>
            </tr>

            <tr>
              <th className="th25">Payment Accepted By:</th>
              <td className="td25">{processor_name ? processor_name : "-"}</td>
              <th className="th25">Installment:</th>
              <td className="td25">
                Installment No {installment_number} - {installment_name}
              </td>
            </tr>
            <tr>
              <th className="th25">Amount:</th>
              <td className="td25">₹{amount}</td>
              <th className="th25">Late Fee:</th>
              <td className="td25">₹{late_fee}</td>
            </tr>
            <tr>
              <th className="th50" colspan="2">
                Amount Paid:
              </th>
              <td className="td50" colspan="2">
                <strong> ₹{final_fee} </strong>
              </td>
            </tr>
          </tbody>
        </table>
        {components && components.length > 0 && (
          <div className="receipt-section">
            <div className="receipt-section-title">Payment Breakdown</div>
            <table className="receipt-table">
              <thead>
                <tr>
                  <th className="th50">Component</th>
                  <th className="th50">Amount</th>
                </tr>
              </thead>
              <tbody>
                {components.map((comp, index) => (
                  <tr key={index}>
                    <td className="th50">{comp.component_name}</td>
                    <td className="td50">₹{comp.amount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div class="receipt-compInfo">
        <p>UDISE No - 27250203806</p>
      </div>
      <div className="receipt-footer">
        <p>
          This is a system-generated receipt and does not require a signature.
        </p>
        <p>Thank you for your payment!</p>
      </div>
    </div>
  );
};

const ReceiptKCEPL = ({ details }) => {
  const { installmentResult, components, paymentDetails } = details;
  const {
    first_name,
    middle_name,
    last_name,
    session,
    order_id,
    amount,
    late_fee,
    final_fee,
    adm_class,
    division,

    roll_no,
    installment_number,
    installment_name,
  } = installmentResult;

  // Tax Calculations
  const taxRate = 0.09; // 9% for CGST and 9% for SGST
  const taxableValue = Number(amount) / (1 + 2 * taxRate);
  const cgst = taxableValue * taxRate;
  const sgst = taxableValue * taxRate;
  const totalTax = cgst + sgst;

  const { created_at, payment_method, payment_id, processor_name } =
    paymentDetails;

  const formatDateAndTime = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleDateString(); // e.g., "12/24/2023"
    const formattedTime = date.toLocaleTimeString(); // e.g., "12:32:35 AM"
    return `${formattedDate} ${formattedTime}`;
  };

  return (
    <div className="receipt-kcepl">
      <div className="receipt-header">
        <div className="receipt-header-logo">
          <img src={logo} alt="Logo" className="logo" />
        </div>
        <div className="receipt-header-text">
          <div className="receipt-header-text-name">
            Krishna Chaitanya Educare Private Ltd.
          </div>
          <div className="receipt-header-text-add">
            State: Maharashtra. Code: 27.
          </div>
        </div>
      </div>
      <hr />
      <h4 style={{ textAlign: "center" }}>Payment Receipt</h4>
      <br />
      <div className="receipt-section">
        <div className="receipt-section-title">Payment Details</div>
        <table className="receipt-table">
          <tbody>
            <tr>
              <th className="th25">Student Name:</th>
              <td className="td25">
                {`${first_name} ${middle_name} ${last_name}`}
              </td>
              <th className="th25">Roll No:</th>
              <td className="td25">{roll_no}</td>
            </tr>
            <tr>
              <th className="th25">Class:</th>
              <td className="td25">{`${adm_class} - ${division}`}</td>
              <th className="th25">Session:</th>
              <td className="td25">{session}</td>
            </tr>
            <tr>
              <th className="th25">Order ID:</th>
              <td className="td25">{order_id}</td>
              <th className="th25">Transaction No:</th>
              <td className="td25">{payment_id}</td>
            </tr>
            <tr>
              <th className="th25">Date:</th>
              <td className="td25">{formatDateAndTime(created_at)}</td>
              <th className="th25">Payment Method:</th>
              <td className="td25">{payment_method}</td>
            </tr>

            <tr>
              <th className="th25">Payment Accepted By:</th>
              <td className="td25">{processor_name ? processor_name : "-"}</td>
              <th className="th25">Installment:</th>
              <td className="td25">
                Installment No {installment_number} - {installment_name}
              </td>
            </tr>
            <tr>
              <th className="th25">Amount:</th>
              <td className="td25">₹{amount}</td>
              <th className="th25">Late Fee:</th>
              <td className="td25">₹{late_fee}</td>
            </tr>
            <tr>
              <th className="th50" colspan="2">
                Amount Paid:
              </th>
              <td className="td50" colspan="2">
                <strong> ₹{final_fee} </strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {components && components.length > 0 && (
        <div className="receipt-section">
          <div className="receipt-section-title">Payment Breakdown</div>
          <table className="receipt-table">
            <thead>
              <tr>
                <th className="th50">Component</th>
                <th className="th50">Amount</th>
              </tr>
            </thead>
            <tbody>
              {components.map((comp, index) => (
                <tr key={index}>
                  <td className="th50">{comp.component_name}</td>
                  <td className="td50">₹{comp.amount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div className="receipt-section">
        <div className="receipt-section-title">Tax Details</div>

        <table className="receipt-table">
          <thead>
            <tr>
              <th className="th25">Taxable Value</th>
              <th className="th25">CGST (9%)</th>
              <th className="th25">SGST (9%)</th>
              <th className="th25">Total Tax</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="td25">₹{taxableValue.toFixed(2)}</td>
              <td className="td25">₹{cgst.toFixed(2)}</td>
              <td className="td25">₹{sgst.toFixed(2)}</td>
              <td className="td25">₹{(cgst + sgst).toFixed(2)}</td>
            </tr>
          </tbody>
        </table>

        <div class="receipt-compInfo">
          <p>HSN/SAC : 9241</p>
          <p>Company's GSTIN/UIN : 27AAICK1499A1ZJ</p>
          <p>Company's PAN : AAICK1499A </p>
        </div>

        <div className="receipt-footer">
          <p>
            This is a system-generated receipt and does not require a signature.
          </p>
          <p>Thank you for your payment!</p>
        </div>
      </div>
    </div>
  );
};

const ReceiptCCF = ({ details }) => {
  const { installmentResult, components, paymentDetails } = details;
  const {
    first_name,
    middle_name,
    last_name,
    session,
    order_id,
    adm_class,
    division,
    amount,
    late_fee,
    final_fee,
    roll_no,
    installment_number,
    installment_name,
    transaction_no,
  } = installmentResult;

  const { created_at, payment_method, processor_name } = paymentDetails;

  const formatDateAndTime = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleDateString(); // e.g., "12/24/2023"
    const formattedTime = date.toLocaleTimeString(); // e.g., "12:32:35 AM"
    return `${formattedDate} ${formattedTime}`;
  };

  return (
    <div className="receipt-ccf">
      <div className="receipt-header">
        <div className="receipt-header-logo">
          <img src={logoCIS} alt="Logo" className="logo" />
        </div>
        <div className="receipt-header-text">
          <div className="receipt-header-text-name">
            Chaitanya Charitable Foundation
          </div>
          {/* <div className="receipt-header-text-add">
            Chaitanya's Academy, Baramati
          </div> */}
          <div className="receipt-header-text-add">
            Gate no 43, balgude phatta, Sawal
          </div>
          <div className="receipt-header-text-add">
            Bhigwan road, Baramati 413133.
          </div>
        </div>
      </div>
      <hr />
      <h4 style={{ textAlign: "center" }}>Payment Receipt</h4>
      <div className="receipt-section">
        <div className="receipt-section-title">Payment Details</div>
        <table className="receipt-table">
          <tbody>
            <tr>
              <th className="th50">Student Name:</th>
              <td className="td50">{`${first_name} ${middle_name} ${last_name}`}</td>
            </tr>
            <tr>
              <th className="th50">Roll No:</th>
              <td className="td50">{roll_no}</td>
            </tr>
            <tr>
              <th className="th50">Class:</th>
              <td className="td50">{`${adm_class} - ${division}`}</td>
            </tr>
            <tr>
              <th className="th50">Session:</th>
              <td className="td50">{session}</td>
            </tr>
            <tr>
              <th className="th50">Order Id:</th>
              <td className="td50">{order_id}</td>
            </tr>
            <tr>
              <th className="th50">Transaction No:</th>
              <td className="td50">{transaction_no ? transaction_no : "-"}</td>
            </tr>
            <tr>
              <th className="th50">Date:</th>
              <td className="td50">{formatDateAndTime(created_at)}</td>
            </tr>
            <tr>
              <th className="th50">Payment Mode:</th>
              <td className="td50">{payment_method}</td>
            </tr>

            <tr>
              <th className="th50">Payment Accepted By:</th>
              <td className="td50">{processor_name ? processor_name : "-"}</td>
            </tr>
            <tr>
              <th className="th50">Installment:</th>
              <td className="td50">
                Installment No {installment_number} - {installment_name}
              </td>
            </tr>
            <tr>
              <th className="th50">Amount:</th>
              <td className="td50">₹{amount}</td>
            </tr>
            <tr>
              <th className="th50">Late Fees:</th>
              <td className="td50">₹{late_fee}</td>
            </tr>
            <tr>
              <th className="th50">Amount Paid:</th>
              <td className="td50">
                <strong> ₹{final_fee} </strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {components && components.length > 0 && (
        <div className="receipt-section">
          <div className="receipt-section-title">Payment Breakdown</div>
          <table className="receipt-table">
            <thead>
              <tr>
                <th className="th50">Component</th>
                <th className="th50">Amount</th>
              </tr>
            </thead>
            <tbody>
              {components.map((comp, index) => (
                <tr key={index}>
                  <td className="th50">{comp.component_name}</td>
                  <td className="td50">₹{comp.amount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div className="receipt-footer">
        <p>
          This is a system-generated receipt and does not require a signature.
        </p>
        <p>Thank you for your payment!</p>
      </div>
    </div>
  );
};

const AcceptPayment = ({
  installmentId,
  amount,
  name,
  onClose,
  handleRefresh,
}) => {
  const [paymentMethod, setPaymentMethod] = useState("");
  const [receiptNumber, setReceiptNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const handlePaymentAcceptance = async () => {
    if (amount > 0 && (!paymentMethod || !receiptNumber)) {
      alert("Please fill in all fields.");
      return;
    }

    setIsLoading(true);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(
        `${BE_URL}/api/acceptPayment`,
        {
          installmentId,
          paymentMethod: amount > 0 ? paymentMethod : "Paid_By_Discount",
          transaction_no:
            amount > 0 ? receiptNumber : `Paid_By_Discount${installmentId}`,
        },
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );

      if (response.data.status === "success") {
        alert("Payment accepted successfully.");
        handleRefresh();
        onClose(); // Close modal on success
      } else {
        setError("Failed to accept payment.");
      }
    } catch (error) {
      console.error("Error accepting payment:", error);
      alert("Error accepting payment. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="accept-payment">
      <h2>Accept Payment</h2>
      <div className="info">
        {name} - &#8377; {amount}{" "}
      </div>

      {amount > 0 && (
        <>
          <div className="form-group">
            <label htmlFor="payment-method">Payment Method</label>
            <select
              id="payment-method"
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
            >
              <option value="">Select Method</option>
              <option value="Cash">Cash</option>
              <option value="Demand Draft">Demand Draft (DD)</option>
              <option value="Bank Transfer">Bank Transfer</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="receipt-number">Transaction Number</label>
            <input
              type="text"
              id="receipt-number"
              value={receiptNumber}
              onChange={(e) => setReceiptNumber(e.target.value)}
            />
          </div>
        </>
      )}

      <div className="action-buttons">
        <button onClick={handlePaymentAcceptance} disabled={isLoading}>
          {isLoading ? "Processing..." : "Accept Payment"}
        </button>
      </div>
    </div>
  );
};
