import "./HalfList.scss";

import React, { Children, useEffect, useState } from "react";
import "./HalfList.scss"; // Assuming you have a corresponding SCSS file
import HalfDetails from "../HalfDetails/HalfDetails";
import Button from "../Button/Button";
import Modal from "../Modal/Modal";

const HalfList = ({
  data,
  fields,
  setSelectedItem,
  isAddModalOpen,
  elem,
  can_create,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isAddOpen, setIsAddOpen] = useState(false);

  useEffect(() => {
    if (!isAddModalOpen) {
      setIsAddOpen(false);
    }

    console.log("Effectiog");
  }, [isAddModalOpen]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredData = searchTerm
    ? data.filter((item) =>
        fields.some(
          (field) =>
            item[field] &&
            item[field]
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
        )
      )
    : data;

  return (
    <div className="halflist">
      <Modal isOpen={isAddOpen} onClose={() => setIsAddOpen(false)}>
        {elem}
      </Modal>

      <div className="halflist-header">
        {/* <div className="halflist-header-count">
          {filteredData.length} Records.
        </div> */}
        <div className="halflist-header-search">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>

        {can_create && (
          <div className="halflist-header-add">
            <Button
              type={`curvedSmall`}
              text={`Add`}
              onClick={() => {
                window.scrollTo(0, 0);
                setIsAddOpen(true);
              }}
            />
          </div>
        )}
      </div>

      <div className="list-section">
        <table>
          <thead>
            <tr className="list-section-rowHead">
              {fields.map((field) => (
                <th key={field}>{field.replace(/_/g, " ").toUpperCase()}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item) => (
              <tr key={item.id} onClick={() => setSelectedItem(item)}>
                {fields.map((field) => (
                  <td key={field}>{item[field]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="halflist-footer">
        {filteredData.length} records found.
      </div>
    </div>
  );
};

export default HalfList;
