import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./InstallmentsWithDiscountsDisplay.scss";
import CustomTextInput from "../CustomTextInput/CustomTextInput";
import CustomNumberInput from "../CustomNumberInput/CustomNumberInput";
import Button from "../Button/Button";
import axios from "axios";
import DropDown from "../DropDown/DropDown";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const InstallmentsWithDiscountsDisplay = ({
  title,
  fee,
  scholarshipAmount = 0,
  additial_amount = 0,
  installments,
  setInstallments,
}) => {
  const [accounts, setAccounts] = useState([]);
  const [tot_fee, setTot_fee] = useState(fee);
  const [finalFee, setFinalFee] = useState();

  useEffect(() => {
    setTot_fee(fee);
    setFinalFee(
      Number(fee) - Number(scholarshipAmount) - Number(additial_amount)
    );
  }, [fee, scholarshipAmount, additial_amount]);

  const fetchCategories = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens")); // Ensure you have tokens stored in localStorage
      const response = await axios.get(
        `${BE_URL}/api/general/categories/Accounts`,
        {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        }
      );
      setAccounts(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
      if (error.response && error.response.status === 401) {
        alert("Session expired. Please login again.");
      } else {
        alert("Failed to fetch categories. Please try again later.");
      }
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleAccountChange = (index, value) => {
    const acc = accounts.filter((item) => item.id === value);
    const newInstallments = (installments || []).map((inst, idx) => {
      if (idx === index) {
        return { ...inst, account: value };
      }
      return inst;
    });
    setInstallments(newInstallments);
  };

  useEffect(() => {
    const newFinalFee =
      Number(tot_fee) - Number(scholarshipAmount) - Number(additial_amount);
    setFinalFee(newFinalFee);
  }, [tot_fee, scholarshipAmount, additial_amount]);

  const addDiscountRow = (index) => {
    const newInstallments = [...installments];
    if (!newInstallments[index].discount) {
      newInstallments[index].discount = { name: "", amount: null };
    }
    setInstallments(newInstallments);
  };

  const removeDiscountRow = (index) => {
    const newInstallments = [...installments];
    delete newInstallments[index].discount;
    setInstallments(newInstallments);
  };

  const handleInstallmentChange = (index, field, value) => {
    const newInstallments = installments.map((inst, idx) =>
      idx === index ? { ...inst, [field]: value } : inst
    );
    setInstallments(newInstallments);
  };

  const handleDiscountChange = (index, field, value) => {
    const newInstallments = [...installments];
    newInstallments[index].discount[field] = value;
    setInstallments(newInstallments);
  };

  const removeInstallmentRow = (indexToRemove) => {
    const installmentToRemove = installments[indexToRemove];
    if (installmentToRemove) {
      const discountAmount = installmentToRemove.discount
        ? installmentToRemove.discount.amount
        : 0;
      const netAmount = installmentToRemove.amount - discountAmount;
      setTot_fee(tot_fee - netAmount);
    }

    const filteredInstallments = installments.filter(
      (_, index) => index !== indexToRemove
    );
    setInstallments(filteredInstallments);
  };

  const totalInstallments = installments.reduce((acc, curr) => {
    const discountAmount = curr.discount ? Number(curr.discount.amount) : 0;
    return acc + (Number(curr.amount) - discountAmount);
  }, 0);

  // Drag and drop handlers
  // const onDragEnd = (result) => {
  //   if (!result.destination) return;
  //   const newInstallments = Array.from(installments);
  //   const [movedItem] = newInstallments.splice(result.source.index, 1);
  //   newInstallments.splice(result.destination.index, 0, movedItem);
  //   setInstallments(newInstallments);
  // };

  // Drag and drop handlers
  const onDragEnd = (result) => {
    if (!result.destination) return;

    // Reorder installments
    const newInstallments = Array.from(installments);
    const [movedItem] = newInstallments.splice(result.source.index, 1);
    newInstallments.splice(result.destination.index, 0, movedItem);

    // Update installment_number based on the new order
    const updatedInstallments = newInstallments.map((inst, index) => ({
      ...inst,
      installment_number: index + 1, // Assign the new installment_number
    }));

    // Set updated installments
    setInstallments(updatedInstallments);
  };

  return (
    <div className="instDiscount">
      <div className="instDiscount-meta">
        <div className="instDiscount-meta-title">{title}</div>
        <div className="instDiscount-meta-content">
          <div>Total Fee: Rs {tot_fee}</div>
          <div>Scholarship Amount: Rs {scholarshipAmount || 0}</div>
          <div>Additional Discount: Rs {additial_amount || 0}</div>
          <div>Final Fee: Rs {finalFee}</div>
          <div>Total Installments: Rs {totalInstallments}</div>
        </div>
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="installments-list">
          {(provided) => (
            <div
              className="instDiscount-items"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {installments.map((inst, index) => (
                <Draggable
                  key={index}
                  draggableId={`item-${index}`}
                  index={index}
                >
                  {(provided) => (
                    <div
                      className="instDiscount-row"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div className="instDiscount-row-values">
                        <div className="instDiscount-row-values-name">
                          <CustomTextInput
                            placeholder="Installment Name"
                            value={inst.installment_name}
                            onChange={(e) =>
                              handleInstallmentChange(
                                index,
                                "installment_name",
                                e.target.value
                              )
                            }
                          />
                        </div>
                        <div className="instDiscount-row-values-amount">
                          <CustomNumberInput
                            placeholder="Amount"
                            value={inst.amount}
                            onChange={(e) =>
                              handleInstallmentChange(
                                index,
                                "amount",
                                Number(e)
                              )
                            }
                          />
                        </div>

                        <div className="instDiscount-row-values-discount">
                          {inst.discount ? (
                            <>
                              <div className="instDiscount-row-values-discount-name">
                                <CustomTextInput
                                  placeholder="Discount Name"
                                  value={inst.discount.name}
                                  onChange={(e) =>
                                    handleDiscountChange(
                                      index,
                                      "name",
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                              <div className="instDiscount-row-values-discount-amount">
                                <CustomNumberInput
                                  value={inst.discount.amount}
                                  placeholder={`Amount`}
                                  onChange={(e) =>
                                    handleDiscountChange(
                                      index,
                                      "amount",
                                      Number(e)
                                    )
                                  }
                                />
                              </div>
                              <div className="instDiscount-row-values-discount-button">
                                <Button
                                  type="minired"
                                  text="X"
                                  onClick={() => removeDiscountRow(index)}
                                />
                              </div>
                            </>
                          ) : (
                            <Button
                              text="Add Discount"
                              onClick={() => addDiscountRow(index)}
                              type={`mini`}
                            />
                          )}
                        </div>
                        <div className="instDiscount-row-values-account">
                          <DropDown
                            options={accounts.map((item) => item.name)}
                            value={inst.account || ""}
                            onChange={(e) => handleAccountChange(index, e)}
                          />
                        </div>
                        <div className="instDiscount-row-values-due">
                          <CustomTextInput
                            type="date"
                            placeholder="Due Date"
                            value={
                              inst.due_date ? inst.due_date.split("T")[0] : null
                            }
                            onChange={(e) =>
                              handleInstallmentChange(
                                index,
                                "due_date",
                                e.target.value
                              )
                            }
                          />
                        </div>
                        <div className="instDiscount-row-values-remove">
                          <Button
                            type="minired"
                            text="X"
                            onClick={() => removeInstallmentRow(index)}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div className="instDiscount-button">
        <Button
          type={`mini`}
          text="Add Installment"
          onClick={() =>
            setInstallments([
              ...installments,
              {
                installment_number: installments.length + 1,
                installment_name: "",
                amount: null,
                account: null,
                due_date: new Date().toISOString().split("T")[0],
                discount: undefined,
              },
            ])
          }
        />
      </div>
      {finalFee !== totalInstallments && (
        <div className="instDiscount-conflict">
          The total of installments does not match the final fee.
        </div>
      )}
    </div>
  );
};

export default InstallmentsWithDiscountsDisplay;
