import React, { useEffect, useState } from "react";
import "./InstallmentsStudentDetailsEdit.scss";
import CustomTextInput from "../CustomTextInput/CustomTextInput";
import CustomNumberInput from "../CustomNumberInput/CustomNumberInput";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Button from "../Button/Button";
import axios from "axios";
import DropDown from "../DropDown/DropDown";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const InstallmentsStudentDetailsEdit = ({
  session,
  student_id,
  student_roll_no,
  title,
  fee,
  finalFee,
  scholarshipAmount = 0,
  additial_amount = 0,
  installments,
  setInstallments,
  handleRefresh,
}) => {
  const [accounts, setaccounts] = useState([]);

  const fetchcategories = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens")); // Ensure you have tokens stored in localStorage
      const response = await axios.get(
        `${BE_URL}/api/general/categories/Accounts`,
        {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        }
      );
      setaccounts(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
      // Handle specific errors (e.g., unauthorized, server issues)
      if (error.response && error.response.status === 401) {
        // Handle unauthorized error, e.g., redirect to login or refresh token
        alert("Session expired. Please login again.");
      } else {
        alert("Failed to fetch categories. Please try again later.");
      }
    } finally {
    }
  };

  // Function to handle adding or updating an installment
  const handleSaveInstallment = async (installment, isUpdate = false) => {
    if (!installment.account) {
      alert("Please select the account!");
      return;
    }
    const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
    const url = `${BE_URL}/api/installment${
      isUpdate ? `/${installment.id}` : ""
    }`;
    const method = isUpdate ? "put" : "post";
    try {
      const response = await axios[method](
        url,
        { student_id, student_roll_no, installment },
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );

      if (!isUpdate) {
        // When adding a new installment, update the installments array with the new installment from the response
        const newInstallments = [...installments];
        newInstallments[installments.length - 1] = {
          ...newInstallments[installments.length - 1],
          id: response.data.id,
        }; // Append the new ID to the newly added installment
        setInstallments(newInstallments);
      }

      handleRefresh();

      alert(`Installment ${isUpdate ? "updated" : "added"} successfully.`);
    } catch (error) {
      console.error("Error saving installment:", error);
      alert("Failed to save installment.");
    }
  };

  // Function to handle deleting an installment
  const handleDeleteInstallment = async (id, indexToRemove) => {
    if (!window.confirm("Are you sure you want to delete the installment?")) {
      return;
    }
    const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
    try {
      await axios.delete(`${BE_URL}/api/installment/${id}`, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });

      const filteredInstallments = installments.filter(
        (_, index) => index !== indexToRemove
      );
      setInstallments(filteredInstallments);

      handleRefresh();

      alert("Installment deleted successfully.");
    } catch (error) {
      console.error("Error deleting installment:", error);
      alert("Failed to delete installment.");
    }
  };
  // Function to handle refund an installment
  const handleRefundInstallment = async (id) => {
    if (!window.confirm("Are you sure you want to refund this installment?")) {
      return;
    }

    const refundAmount = prompt(
      "Please enter the refund amount (enter 0 if not applicable):"
    );
    if (refundAmount === null) {
      return;
    }

    const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
    try {
      await axios.put(
        `${BE_URL}/api/installment/refund/${id}`,
        { refundAmount },
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );

      handleRefresh();

      alert(
        "Installment refunded successfully. Please check Expenses for Refund Details."
      );
    } catch (error) {
      console.error("Error deleting installment:", error);
      alert("Failed to delete installment.");
    }
  };

  // Add these functions to the appropriate button onClick handlers within your component render method

  useEffect(() => {
    fetchcategories();
  }, []);

  const handleAccountChange = (index, value) => {
    const acc = accounts.filter((item) => item.id === value);
    const newInstallments = (installments || []).map((inst, idx) => {
      if (idx === index) {
        return { ...inst, account: value };
      }
      return inst;
    });
    setInstallments(newInstallments);
  };

  const addDiscountRow = (index) => {
    const newInstallments = [...installments];
    if (!newInstallments[index].discount) {
      newInstallments[index].discount = { name: "", amount: null };
    }
    setInstallments(newInstallments);
  };

  const removeDiscountRow = (index) => {
    const newInstallments = [...installments];
    delete newInstallments[index].discount;
    setInstallments(newInstallments);
  };

  const handleInstallmentChange = (index, field, value) => {
    const newInstallments = installments.map((inst, idx) =>
      idx === index ? { ...inst, [field]: value } : inst
    );
    setInstallments(newInstallments);
  };

  const handleDiscountChange = (index, field, value) => {
    const newInstallments = [...installments];
    newInstallments[index].discount[field] = value;
    setInstallments(newInstallments);
  };

  // Add a new installment to the list
  const addNewInstallment = () => {
    const newInstallment = {
      installment_name: "",
      amount: "",
      due_date: new Date().toISOString().split("T")[0], // Today's date as default
      account: null, // Default to the first account if available
      discount: { name: null, amount: null },
      status: "UnPaid",
      installment_number: installments.length + 1,
    };
    setInstallments([...installments, newInstallment]);
  };

  // Update or Add installment based on if it has an ID
  const saveInstallment = (installment, index) => {
    installment = { ...installment, session };
    if (installment.id) {
      handleSaveInstallment(installment, true); // Update existing
    } else {
      handleSaveInstallment(installment); // Add new
    }
  };

  const totalInstallments = installments.reduce((acc, curr) => {
    const discountAmount = curr.discount ? Number(curr.discount.amount) : 0;
    return acc + (Number(curr.amount) - discountAmount);
  }, 0);

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const updatedInstallments = Array.from(installments);
    const [movedItem] = updatedInstallments.splice(result.source.index, 1);
    updatedInstallments.splice(result.destination.index, 0, movedItem);

    // Update the installment_number based on the new order
    updatedInstallments.forEach((inst, idx) => {
      inst.installment_number = idx + 1;
    });

    setInstallments(updatedInstallments);

    try {
      // Make API call to update installment numbers
      await updateInstallmentNumbersAPI(updatedInstallments);
    } catch (error) {
      console.error("Failed to update installment numbers, reverting changes.");
      // Revert the changes in case of error
      setInstallments(installments);
    }
  };

  const updateInstallmentNumbersAPI = async (updatedInstallments) => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));

      // Extract only id and installment_number from each installment
      const payload = updatedInstallments.map((inst) => ({
        id: inst.id,
        installment_number: inst.installment_number,
      }));

      const response = await axios.post(
        `${BE_URL}/api/updateInstallmentNumbers/`,
        { installments: payload },
        {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error updating installment numbers:", error);
      throw error;
    }
  };

  return (
    <div className="instDiscount">
      <div className="instDiscount-meta">
        <div className="instDiscount-meta-title">{title}</div>
        <div className="instDiscount-meta-content">
          <div>Total Fee: Rs {fee}</div>
          <div>
            Scholarship Amount: Rs {scholarshipAmount ? scholarshipAmount : 0}
          </div>
          <div>
            Additional Discount: Rs {additial_amount ? additial_amount : 0}
          </div>
          {/* <div>Final Fee: Rs {finalFee}</div> */}
          <div>Total Installments: Rs {totalInstallments}</div>
        </div>
      </div>

      <div className="instDiscount-row">
        <div className="instDiscount-row-header">
          <div className="instDiscount-row-header-name">Name</div>
          <div className="instDiscount-row-header-amount">Amount</div>
          <div className="instDiscount-row-header-late">Late Fee</div>
          <div className="instDiscount-row-header-discount">Discount</div>
          <div className="instDiscount-row-header-account">Account</div>
          <div className="instDiscount-row-header-due">Due on</div>
        </div>
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="installments-list">
          {(provided) => (
            <div
              className="installments-items"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {installments.map((inst, index) => (
                <Draggable
                  key={inst.id}
                  draggableId={`item-${inst.id}`}
                  index={index}
                >
                  {(provided) => (
                    <div
                      key={inst.id}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="instDiscount-row "
                    >
                      <div className="instDiscount-row-values">
                        <div className="instDiscount-row-values-name">
                          <CustomTextInput
                            placeholder="Installment Name"
                            value={inst.installment_name}
                            onChange={(e) =>
                              handleInstallmentChange(
                                index,
                                "installment_name",
                                e.target.value
                              )
                            }
                          />
                        </div>
                        <div className="instDiscount-row-values-amount">
                          <CustomNumberInput
                            placeholder="Amount"
                            value={inst.amount}
                            onChange={(e) =>
                              handleInstallmentChange(
                                index,
                                "amount",
                                Number(e)
                              )
                            }
                          />
                        </div>
                        <div className="instDiscount-row-values-late">
                          <CustomNumberInput
                            placeholder="Late Fee"
                            value={inst.late_fee}
                            onChange={(e) =>
                              handleInstallmentChange(
                                index,
                                "late_fee",
                                Number(e)
                              )
                            }
                          />
                        </div>

                        <div className="instDiscount-row-values-discount">
                          {inst.discount && (
                            <>
                              <div className="instDiscount-row-values-discount-name">
                                <CustomTextInput
                                  placeholder="Discount Name"
                                  value={inst.discount.name}
                                  onChange={(e) =>
                                    handleDiscountChange(
                                      index,
                                      "name",
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                              <div className="instDiscount-row-values-discount-amount">
                                <CustomNumberInput
                                  value={inst.discount.amount}
                                  placeholder={`Amount`}
                                  onChange={(e) =>
                                    handleDiscountChange(
                                      index,
                                      "amount",
                                      Number(e)
                                    )
                                  }
                                />
                              </div>
                              <div className="instDiscount-row-values-discount-button">
                                <Button
                                  type="minired"
                                  text="X"
                                  onClick={() => removeDiscountRow(index)}
                                />
                              </div>
                            </>
                          )}
                          {!inst.discount && (
                            <Button
                              text="Add Discount"
                              onClick={() => addDiscountRow(index)}
                              type={`mini`}
                            />
                          )}
                        </div>
                        <div className="instDiscount-row-values-account">
                          <DropDown
                            options={accounts.map((item) => item.name)}
                            value={inst.account || ""}
                            onChange={(e) => handleAccountChange(index, e)}
                          />
                        </div>
                        <div className="instDiscount-row-values-due">
                          <CustomTextInput
                            type="date"
                            placeholder="Due Date"
                            value={
                              inst.due_date ? inst.due_date.split("T")[0] : null
                            }
                            onChange={(e) =>
                              handleInstallmentChange(
                                index,
                                "due_date",
                                e.target.value
                              )
                            }
                          />
                        </div>
                        <div className="instDiscount-row-values-remove">
                          {inst.status === "Paid" ? (
                            <>
                              <Button
                                type="minired"
                                text="Refund"
                                onClick={() => handleRefundInstallment(inst.id)}
                              />
                            </>
                          ) : null}
                          {inst.status === "UnPaid" ? (
                            <>
                              <Button
                                type="mini"
                                text="Save"
                                onClick={() => saveInstallment(inst, index)}
                              />
                              <Button
                                type="minired"
                                text="X"
                                onClick={() =>
                                  handleDeleteInstallment(inst.id, index)
                                }
                              />
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div className="instDiscount-button">
        <Button
          type={`mini`}
          text="Add Installment"
          onClick={addNewInstallment}
        />
      </div>
      {/* {finalFee !== totalInstallments && (
        <div className="instDiscount-conflict">
          The total of installments does not match the final fee.
        </div>
      )} */}
    </div>
  );
};

export default InstallmentsStudentDetailsEdit;
